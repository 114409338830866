import { HttpStatusCode } from "@angular/common/http";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { ReportingUserEntity, ReportPilotEntity, ReportUavEntity } from "@dtm-frontend/uav-identification-shared-lib/report";
import { RemoteId } from "remote-id";
import { ReportError, ReportErrorType, ReportFormData, ReportIdentity } from "../models/report.model";

export interface ReportRequestPayload {
    uav: ReportUavEntity;
    pilot: ReportPilotEntity;
    reportingUser: Partial<ReportingUserEntity>;
    reasons: string[];
    additionalInfo: string | null;
    referenceDate: Date;
    interventionNote: string;
    completed: boolean;
    remoteIds: Array<Partial<RemoteId.Data>>;
}
export interface ReportResponseBody extends ReportRequestPayload {
    number: string;
    createdAt: Date;
}

export interface ReportIdentityVerificationRequestPayload {
    phoneNumber: PhoneNumber;
    languageTag: string;
}

export interface ReportIdentityVerificationResponseBody {
    id: string;
    phoneNumber: PhoneNumber;
    confirmed: boolean;
}

interface ReportIdentityVerificationErrorResponseBody {
    error: {
        status: HttpStatusCode;
        fieldErrors: {
            fieldName: "PhoneNumber";
            code: "AlreadyUsed" | "MobilePhoneNumber";
        }[];
    };
}

interface ReportIdentityVerificationCodeErrorResponseBody {
    error: {
        status: HttpStatusCode;
        fieldErrors: {
            fieldName: "ConfirmationCode";
            code: "Incorrect";
        }[];
    };
}

interface SendReportErrorResponseBody {
    status: HttpStatusCode;
}

export function convertReportFormDataToReportRequestPayload(reportFormData: ReportFormData): ReportRequestPayload {
    const { pilot, uav, reasons, additionalInfo, user, reportStartedAt, interventionNote, isIntervention, remoteIds } =
        reportFormData ?? {};

    return {
        uav: {
            location: {
                latitude: uav.latitude,
                longitude: uav.longitude,
            },
            height: uav.height,
        },
        pilot: {
            visible: pilot.isVisible,
            appearance: pilot.appearance,
        },
        reportingUser: {
            location: {
                latitude: user.latitude,
                longitude: user.longitude,
            },
            phoneNumber: user.phoneNumber,
        },
        reasons,
        additionalInfo,
        referenceDate: reportStartedAt,
        interventionNote: interventionNote,
        completed: isIntervention,
        remoteIds,
    };
}

export function convertReportIdentityVerificationResponseBodyToReportIdentity(
    response: ReportIdentityVerificationResponseBody
): ReportIdentity {
    return {
        verificationId: response.id,
        phoneNumber: response.phoneNumber,
        isVerified: response.confirmed,
    };
}

export function convertReportIdentityVerificationErrorResponseBodyToReportError(
    errorResponse: ReportIdentityVerificationErrorResponseBody
): ReportError {
    const fieldErrors = errorResponse?.error?.fieldErrors;

    if (!fieldErrors) {
        return { type: ReportErrorType.Unknown };
    }

    const phoneNumberErrorCode = fieldErrors.find((fieldError) => fieldError.fieldName === "PhoneNumber")?.code;

    if (phoneNumberErrorCode) {
        return {
            type: phoneNumberErrorCode === "AlreadyUsed" ? ReportErrorType.PhoneNumberAlreadyUsed : ReportErrorType.PhoneNumberInvalid,
        };
    }

    return { type: ReportErrorType.Unknown };
}

export function convertReportIdentityVerificationCodeErrorResponseBodyToReportError(
    errorResponse: ReportIdentityVerificationCodeErrorResponseBody
): ReportError {
    const status = errorResponse?.error?.status;
    if (status === HttpStatusCode.NotFound) {
        return { type: ReportErrorType.VerificationCodeExpired };
    }

    const fieldErrors = errorResponse?.error?.fieldErrors;
    const isInvalidCode = fieldErrors?.find((fieldError) => fieldError.fieldName === "ConfirmationCode")?.code === "Incorrect";

    if (isInvalidCode) {
        return { type: ReportErrorType.VerificationCodeInvalid };
    }

    return { type: ReportErrorType.Unknown };
}

export function convertSendReportErrorResponseBodyToReportError(errorResponse: SendReportErrorResponseBody): ReportError {
    if (errorResponse.status === HttpStatusCode.NotFound) {
        return { type: ReportErrorType.ReportAbroad };
    }

    return { type: ReportErrorType.CannotSubmitReport };
}
