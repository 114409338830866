import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Sort } from "@angular/material/sort";
import { FilterType, FiltersMap } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ReportListFilters, ReportStatus } from "@dtm-frontend/uav-identification-shared-lib/report";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, distinctUntilChanged, tap } from "rxjs";

interface MyInterventionsListFiltersComponentState {
    appliedFiltersCount: number | undefined;
    areInitialFiltersProvided: boolean;
    statuses: ReportStatus[];
}

const FILTERS_MAP: FiltersMap[] = [
    {
        key: "text",
        filterLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.textSearchLabel",
        type: FilterType.TextEllipsis,
    },
    {
        key: "statuses",
        filterLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.statusLabel",
        filterValueLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.statusValueLabel",
    },
    {
        key: "createdDateFrom",
        filterLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.createdDateFromLabel",
        filterValueLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.createdAtValueLabel",
        type: FilterType.Date,
    },
    {
        key: "createdDateTo",
        filterLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.createdDateToLabel",
        filterValueLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.createdAtValueLabel",
        type: FilterType.Date,
    },
    {
        key: "isEmergencyReport",
        filterLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.isEmergencyReportLabel",
        filterValueLabel: "uavIdClientLibMyInterventions.myInterventionsListFilters.toggleFilterValueLabel",
    },
];
const DEBOUNCE_TIME = 300;

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-my-interventions-list-filters",
    templateUrl: "./my-interventions-list-filters.component.html",
    styleUrls: ["./my-interventions-list-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MyInterventionsListFiltersComponent {
    @Input() public set initialFilters(value: ReportListFilters | undefined) {
        const areInitialFiltersProvided =
            !!value && Object.values(value).some((filter) => !!filter && (!Array.isArray(filter) || filter.length));

        this.localStore.patchState({ areInitialFiltersProvided });

        if (!value) {
            return;
        }

        this.setInitialFilters(value);
    }
    @Input() public set statuses(value: ReportStatus[] | undefined) {
        this.localStore.patchState({ statuses: value ?? [] });
    }
    @Input() public set sort(value: Sort | undefined) {
        this.sortControl.setValue(value ?? null);
    }

    @Output() protected readonly sortChange = new EventEmitter<Sort>();
    @Output() private readonly filtersChange = new EventEmitter<Partial<ReportListFilters>>();

    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly FILTERS_MAP = FILTERS_MAP;
    protected readonly SORTING_OPTIONS = [
        { active: "number", direction: "asc", translateKey: "uavIdClientLibMyInterventions.myInterventionsListFilters.sortNumberLabel" },
        { active: "number", direction: "desc", translateKey: "uavIdClientLibMyInterventions.myInterventionsListFilters.sortNumberLabel" },
        {
            active: "reportedAt",
            direction: "asc",
            translateKey: "uavIdClientLibMyInterventions.myInterventionsListFilters.sortReportedAtLabel",
        },
        {
            active: "reportedAt",
            direction: "desc",
            translateKey: "uavIdClientLibMyInterventions.myInterventionsListFilters.sortReportedAtLabel",
        },
    ];

    protected readonly appliedFiltersCount$ = this.localStore.selectByKey("appliedFiltersCount");
    protected readonly statuses$ = this.localStore.selectByKey("statuses");
    protected readonly areInitialFiltersProvided$ = this.localStore.selectByKey("areInitialFiltersProvided");

    protected readonly sortControl = new FormControl<Sort | null>(null);

    protected readonly textControl = new FormControl<string | null>(null);
    protected readonly statusesControl = new FormControl<ReportStatus[] | null>(null);
    protected readonly createdDateFromControl = new FormControl<Date | null>(null);
    protected readonly createdDateToControl = new FormControl<Date | null>(null);
    protected readonly isEmergencyReportControl = new FormControl<boolean | null>(null);

    protected readonly filtersFormGroup = new FormGroup({
        text: this.textControl,
        statuses: this.statusesControl,
        createdDateFrom: this.createdDateFromControl,
        createdDateTo: this.createdDateToControl,
        isEmergencyReport: this.isEmergencyReportControl,
    });

    constructor(
        private readonly localStore: LocalComponentStore<MyInterventionsListFiltersComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            appliedFiltersCount: undefined,
            areInitialFiltersProvided: false,
            statuses: [],
        });

        this.watchForFormValueChanges();
    }

    protected clearFilters(): void {
        this.filtersFormGroup.reset({ statuses: [] });
    }

    protected compareSelectOptions(left: Sort | null, right: Sort | null): boolean {
        return left?.active === right?.active && left?.direction === right?.direction;
    }

    private watchForFormValueChanges(): void {
        this.filtersFormGroup.valueChanges
            .pipe(
                debounceTime(DEBOUNCE_TIME),
                distinctUntilChanged(),
                tap((value) => {
                    this.updateAppliedFiltersCount();

                    if (this.filtersFormGroup.invalid) {
                        return;
                    }

                    this.filtersChange.emit(value);
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private updateAppliedFiltersCount(): void {
        if (this.filtersFormGroup.invalid) {
            return;
        }

        const values = Object.values(this.filtersFormGroup.value);
        const appliedFiltersCount = values.flat().filter(Boolean).length;

        this.localStore.patchState({ appliedFiltersCount });
    }

    private setInitialFilters(filters: ReportListFilters): void {
        this.filtersFormGroup.setValue({ ...filters, isEmergencyReport: !!filters.isEmergencyReport }, { emitEvent: false });
        this.updateAppliedFiltersCount();
    }
}
