<ng-container
    *ngrxLet="{
        isIntervention: isIntervention$,
        isFlowSimplified: isFlowSimplified$,
        userPosition: userPosition$,
        flights: flights$,
        reportNumber: reportNumber$,
        policeHeadquartersLayerConfig: policeHeadquartersLayerConfig$
    } as vm"
>
    <dtm-ui-lib-wizard-content [wizardId]="REPORT_WIZARD_ID">
        <dtm-ui-lib-wizard-step-content [stepId]="ReportFormWizardSteps.HowToReport">
            <uav-id-client-lib-report-step-how-to-report
                [canIntervene]="canIntervene$ | ngrxPush"
                [isLoggedIn]="isLoggedIn$ | ngrxPush"
                (next)="verifyOrGoToReport($event)"
            >
            </uav-id-client-lib-report-step-how-to-report>
        </dtm-ui-lib-wizard-step-content>

        <dtm-ui-lib-wizard-step-content [stepId]="ReportFormWizardSteps.VerifyIdentity">
            <uav-id-client-lib-report-step-verify-identity
                (previous)="goToStep(ReportFormWizardSteps.HowToReport)"
                (next)="verifyIdentity($event)"
            >
            </uav-id-client-lib-report-step-verify-identity>
        </dtm-ui-lib-wizard-step-content>

        <dtm-ui-lib-wizard-step-content [stepId]="ReportFormWizardSteps.VerificationCode">
            <uav-id-client-lib-report-step-verification-code
                #verificationCodeComponent
                (previous)="goToStep(ReportFormWizardSteps.VerifyIdentity)"
                (next)="sendVerificationCode($event)"
                (resendCode)="resendVerificationCode()"
            >
            </uav-id-client-lib-report-step-verification-code>
        </dtm-ui-lib-wizard-step-content>

        <dtm-ui-lib-wizard-step-content [stepId]="ReportFormWizardSteps.UavGeolocation">
            <uav-id-client-lib-report-step-uav-geolocation
                [userPosition]="vm.userPosition"
                [flights]="vm.flights"
                [policeHeadquartersLayerConfig]="vm.policeHeadquartersLayerConfig"
                (previous)="goToStep(ReportFormWizardSteps.HowToReport)"
                (next)="goToStep(ReportFormWizardSteps.Details)"
                (uavPositionUpdate)="updateUavPosition($event)"
                (uavOutOfRange)="handleUavOutOfRangeError($event)"
            >
            </uav-id-client-lib-report-step-uav-geolocation>
        </dtm-ui-lib-wizard-step-content>

        <dtm-ui-lib-wizard-step-content [stepId]="ReportFormWizardSteps.Details">
            <uav-id-client-lib-report-step-details
                [isIntervention]="vm.isIntervention"
                [isProcessing]="isReportProcessing$ | ngrxPush"
                [uavPosition]="uavPosition$ | ngrxPush"
                [userPosition]="vm.userPosition"
                [flights]="vm.flights"
                [policeHeadquartersLayerConfig]="vm.policeHeadquartersLayerConfig"
                (previous)="goToStep(ReportFormWizardSteps.UavGeolocation)"
                (next)="submitDetailsForm($event)"
            >
            </uav-id-client-lib-report-step-details>
        </dtm-ui-lib-wizard-step-content>

        <dtm-ui-lib-wizard-step-content [stepId]="ReportFormWizardSteps.Summary">
            <uav-id-client-lib-report-step-summary
                [reportNumber]="vm.reportNumber"
                (next)="callEmergencyAndFinishReport()"
            ></uav-id-client-lib-report-step-summary>
        </dtm-ui-lib-wizard-step-content>

        <dtm-ui-lib-wizard-step-content [stepId]="ReportFormWizardSteps.ThankYouPage">
            <uav-id-client-lib-report-step-thank-you-page
                [isAuthorized]="isAuthorized$ | ngrxPush"
                [isIntervention]="vm.isIntervention"
                [isFlowSimplified]="vm.isFlowSimplified"
                [reportNumber]="vm.reportNumber"
            ></uav-id-client-lib-report-step-thank-you-page>
        </dtm-ui-lib-wizard-step-content>
    </dtm-ui-lib-wizard-content>
</ng-container>
