<ng-container *ngrxLet="reportsPage$ as page">
    <ng-container *ngrxLet="hasGetListErrorOccurred$ as hasGetListErrorOccurred">
        <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
            <dtm-ui-responsive-table *ngrxLet="reports$ as reports" [data]="reports">
                <table
                    desktopTable
                    mat-table
                    matSort
                    class="dtm-table"
                    [dataSource]="reports ?? []"
                    (matSortChange)="sortChange.emit($event)"
                >
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "uavIdClientLibMyInterventions.myInterventionsList.statusLabel" | transloco }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <uav-id-shared-lib-status-badge-menu
                                [configuration]="REPORT_STATUS_BADGE_CONFIGURATION"
                                [availableStatuses]="AVAILABLE_UPDATE_STATUSES"
                                [status]="element.status"
                                (statusChange)="updateStatus($event, element)"
                            ></uav-id-shared-lib-status-badge-menu>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="number">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="number">
                            {{ "uavIdClientLibMyInterventions.myInterventionsList.numberLabel" | transloco }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element?.number ?? "-" }}
                            <span
                                *ngIf="element?.isEmergencyReport"
                                class="chip light"
                                [matTooltip]="'uavIdClientLibMyInterventions.myInterventionsList.emergencyReportTooltip' | transloco"
                            >
                                {{ "uavIdClientLibMyInterventions.myInterventionsList.emergencyReportLabel" | transloco }}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="reportedAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="reportedAt">
                            {{ "uavIdClientLibMyInterventions.myInterventionsList.reportedAtLabel" | transloco }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ (element?.reportedAt | localizeDate : { dateStyle: "short", timeStyle: "short" }) ?? "-" }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "uavIdClientLibMyInterventions.myInterventionsList.locationLabel" | transloco }}
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element?.location ?? "-" }}</td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ "uavIdClientLibMyInterventions.myInterventionsList.actionsLabel" | transloco }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <button
                                type="button"
                                class="button-icon"
                                [matMenuTriggerFor]="rowActionsMenu"
                                [matMenuTriggerData]="{ $implicit: element }"
                            >
                                <dtm-ui-icon name="more"></dtm-ui-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="noResults">
                        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedTableColumns.length">
                            <ng-container
                                *ngIf="hasGetListErrorOccurred; else emptyListTemplate"
                                [ngTemplateOutlet]="getListErrorTemplate"
                            ></ng-container>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="pagination">
                        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedTableColumns.length">
                            <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedTableColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedTableColumns"></tr>
                    <tr
                        mat-footer-row
                        *matFooterRowDef="['noResults']"
                        class="no-results-row"
                        [class.hide-footer]="reports.length || isProcessing"
                    ></tr>
                    <tr mat-footer-row *matFooterRowDef="['pagination']" class="pagination-row" [class.hide-footer]="!reports.length"></tr>
                </table>

                <div *dtmUiMobileTable="let row">
                    <div class="mobile-table-header">
                        <h2>{{ row.number }}</h2>
                        <uav-id-shared-lib-status-badge
                            [configuration]="REPORT_STATUS_BADGE_CONFIGURATION"
                            [status]="row.status"
                        ></uav-id-shared-lib-status-badge>
                        <span
                            *ngIf="row.isEmergencyReport"
                            class="chip light"
                            [matTooltip]="'uavIdClientLibMyInterventions.myInterventionsList.emergencyReportTooltip' | transloco"
                        >
                            {{ "uavIdClientLibMyInterventions.myInterventionsList.emergencyReportLabel" | transloco }}
                        </span>
                        <button
                            type="button"
                            class="mobile-table-header-actions button-icon"
                            [matMenuTriggerFor]="rowActionsMenu"
                            [matMenuTriggerData]="{ $implicit: row }"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </div>

                    <div class="mobile-table-content">
                        <dtm-ui-label-value
                            [label]="'uavIdClientLibMyReports.myReportsList.reportedAtLabel' | transloco"
                            [value]="row.reportedAt | localizeDate : { dateStyle: 'short', timeStyle: 'short' }"
                        >
                        </dtm-ui-label-value>
                        <dtm-ui-label-value
                            [label]="'uavIdClientLibMyReports.myReportsList.locationLabel' | transloco"
                            [value]="row.location ?? '-'"
                        >
                        </dtm-ui-label-value>
                    </div>
                </div>

                <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
                <ng-container
                    *ngIf="hasGetListErrorOccurred; else emptyListTemplate"
                    mobileTableNoData
                    [ngTemplateOutlet]="getListErrorTemplate"
                ></ng-container>
            </dtm-ui-responsive-table>

            <ng-template #paginationTemplate>
                <dtm-ui-pagination *ngIf="page" [pagination]="page" (page)="pageChange.emit($event)"></dtm-ui-pagination>
            </ng-template>
            <ng-template #getListErrorTemplate>
                <dtm-ui-error
                    [errorMessage]="'uavIdClientLibMyInterventions.myInterventionsList.getListErrorMessage' | transloco"
                    (reload)="pageReload.emit()"
                ></dtm-ui-error>
            </ng-template>
            <ng-template #emptyListTemplate>
                <dtm-ui-no-results></dtm-ui-no-results>
            </ng-template>
        </dtm-ui-loader-container>
    </ng-container>
</ng-container>

<mat-menu #rowActionsMenu="matMenu">
    <ng-template matMenuContent let-element>
        <button mat-menu-item type="button" [routerLink]="['/my-interventions', element.id]">
            <dtm-ui-icon name="information"></dtm-ui-icon>
            {{ "uavIdClientLibMyInterventions.myInterventionsList.detailsActionLabel" | transloco }}
        </button>
        <button mat-menu-item type="button" (click)="interventionDialogOpen.emit(element)">
            <dtm-ui-icon name="sticky-note"></dtm-ui-icon>
            {{
                (element?.interventionNote
                    ? "uavIdClientLibMyInterventions.myInterventionsList.editNoteActionLabel"
                    : "uavIdClientLibMyInterventions.myInterventionsList.addNoteActionLabel"
                ) | transloco
            }}
        </button>
    </ng-template>
</mat-menu>
