<ng-container *ngIf="isMapVisible$ | ngrxPush">
    <dtm-map-leaflet-map *ngrxLet="userPosition$ as userPosition">
        <dtm-map-leaflet-user-position [userPosition]="userPosition" [isMarkerInteractive]="false"></dtm-map-leaflet-user-position>
        <dtm-map-leaflet-map-layer
            *ngIf="policeHeadquartersLayerConfig$ | ngrxPush as layerConfig"
            [layerConfig]="layerConfig"
        ></dtm-map-leaflet-map-layer>
        <uav-id-shared-lib-leaflet-flights-layer
            [areDetailsShown]="false"
            [flights]="flights$ | ngrxPush"
        ></uav-id-shared-lib-leaflet-flights-layer>
        <uav-id-shared-lib-report-uav-area
            [position]="uavPosition$ | ngrxPush"
            [userPosition]="userPosition"
            [isDraggable]="false"
            [isInteractive]="false"
        ></uav-id-shared-lib-report-uav-area>
    </dtm-map-leaflet-map>
</ng-container>

<dtm-ui-loader-container class="container container-card" [isShown]="isProcessing$ | ngrxPush">
    <form class="container-content scroll-shadows" [formGroup]="reportForm" (submit)="submit()">
        <span class="step-label">
            {{ "uavIdClientLibReport.reportFormWizardNavigation.stepNumberLabel" | transloco : { stepNumber: 2, stepsNumber: 3 } }}</span
        >

        <h2>{{ "uavIdClientLibReport.reportFormWizardDetailsStep.headerLabel" | transloco }}</h2>

        <section formGroupName="uav">
            <h3>{{ "uavIdClientLibReport.reportFormWizardDetailsStep.uavHeaderLabel" | transloco }}<span class="required">*</span></h3>

            <dtm-ui-radio-group [formControl]="uavHeightControl">
                <dtm-ui-radio-field *ngFor="let option of HEIGHT_ESTIMATION_RADIO_OPTIONS" [value]="option.value">
                    <div class="radio-button-container">
                        <dtm-ui-icon [name]="option.icon" *ngIf="option.icon"></dtm-ui-icon>
                        <div class="label">
                            {{
                                "uavIdClientLibReport.reportFormWizardDetailsStep.uavHeightRadioButtonLabel"
                                    | transloco : { value: option.value }
                            }}
                        </div>
                        <div class="description">
                            {{
                                "uavIdClientLibReport.reportFormWizardDetailsStep.uavHeightRadioButtonDescriptionLabel"
                                    | transloco : { value: option.value }
                            }}
                        </div>
                    </div>
                </dtm-ui-radio-field>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="uavHeightControl; name: 'required'">
                        {{ "uavIdClientLibReport.reportFormWizardDetailsStep.uavHeightRequiredErrorLabel" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>
        </section>

        <section formGroupName="pilot">
            <h3>{{ "uavIdClientLibReport.reportFormWizardDetailsStep.pilotHeaderLabel" | transloco }}<span class="required">*</span></h3>

            <dtm-ui-radio-group [formControl]="pilotIsVisibleControl" (change)="pilotVisibleChange()">
                <dtm-ui-radio-field [value]="true">
                    {{ "uavIdClientLibReport.reportFormWizardDetailsStep.pilotIsVisibleYesRadioButtonLabel" | transloco }}
                </dtm-ui-radio-field>
                <dtm-ui-radio-field [value]="false">
                    {{ "uavIdClientLibReport.reportFormWizardDetailsStep.pilotIsVisibleNoRadioButtonLabel" | transloco }}
                </dtm-ui-radio-field>
                <dtm-ui-form-errors class="preserve-error-space">
                    <div class="field-error" *dtmUiFieldHasError="pilotIsVisibleControl; name: 'required'">
                        {{ "uavIdClientLibReport.reportFormWizardDetailsStep.pilotIsVisibleRequiredErrorLabel" | transloco }}
                    </div>
                </dtm-ui-form-errors>
            </dtm-ui-radio-group>

            <dtm-ui-textarea-field *ngIf="pilotAppearanceControl.enabled" [maxLength]="TEXTAREA_MAX_LENGTH">
                <label>{{ "uavIdClientLibReport.reportFormWizardDetailsStep.pilotAppearanceLabel" | transloco }}</label>
                <textarea matInput [formControl]="pilotAppearanceControl"></textarea>
                <div class="field-error" *dtmUiFieldHasError="pilotAppearanceControl; name: 'maxlength'; error as error">
                    {{
                        "uavIdClientLibReport.reportFormWizardDetailsStep.pilotAppearanceMaxLengthErrorLabel"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
        </section>

        <section>
            <h3>{{ "uavIdClientLibReport.reportFormWizardDetailsStep.reasonsHeaderLabel" | transloco }}<span class="required">*</span></h3>

            <dtm-ui-checkbox-group [formControl]="reasonsControl" (selectionChange)="reasonsChange()">
                <dtm-ui-checkbox-field *ngFor="let reason of REPORT_REASONS" [value]="reason">
                    {{ "uavIdClientLibReport.reportFormWizardDetailsStep.reasonsCheckboxLabel" | transloco : { value: reason } }}
                </dtm-ui-checkbox-field>
            </dtm-ui-checkbox-group>
            <dtm-ui-form-errors class="preserve-error-space">
                <div class="field-error" *dtmUiFieldHasError="reasonsControl; name: 'required'">
                    {{ "uavIdClientLibReport.reportFormWizardDetailsStep.reasonsRequiredErrorLabel" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </section>

        <section>
            <h3>{{ "uavIdClientLibReport.reportFormWizardDetailsStep.additionalInfoHeaderLabel" | transloco }}</h3>
            <dtm-ui-textarea-field [maxLength]="ADDITIONAL_INFO_MAX_LENGTH">
                <textarea
                    matInput
                    [formControl]="additionalInfoControl"
                    [placeholder]="'uavIdClientLibReport.reportFormWizardDetailsStep.additionalInfoPlaceholder' | transloco"
                    [rows]="4"
                ></textarea>
                <div class="field-error" *dtmUiFieldHasError="additionalInfoControl; name: 'maxlength'; error as error">
                    {{
                        "uavIdClientLibReport.reportFormWizardDetailsStep.maxLengthErrorLabel"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
        </section>

        <section *ngIf="interventionNoteControl.enabled">
            <h3>
                {{ "uavIdClientLibReport.reportFormWizardDetailsStep.interventionNoteHeader" | transloco }}
            </h3>

            <dtm-ui-textarea-field [maxLength]="INTERVENTION_NOTE_MAX_LENGTH">
                <textarea matInput [rows]="6" [formControl]="interventionNoteControl"></textarea>
                <div class="field-error" *dtmUiFieldHasError="interventionNoteControl; name: 'maxlength'; error as error">
                    {{
                        "uavIdClientLibReport.reportFormWizardDetailsStep.maxLengthErrorLabel"
                            | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
        </section>
    </form>

    <div class="container-actions">
        <button class="button-secondary" type="button" (click)="previous.emit()">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
            {{ "uavIdClientLibReport.reportFormWizardNavigation.goToPreviousStepButtonLabel" | transloco }}
        </button>
        <button class="button-primary" type="button" (click)="submit()">
            <span>{{ "uavIdClientLibReport.reportFormWizardDetailsStep.submitButtonLabel" | transloco }}</span>
        </button>
    </div>
</dtm-ui-loader-container>
