<ng-container *ngrxLet="appliedFiltersCount$; let appliedFiltersCount">
    <dtm-ui-filters-container [filtersCount]="appliedFiltersCount" [isExpanded]="areInitialFiltersProvided$ | ngrxPush">
        <form
            class="filters-container"
            autocomplete="off"
            [formGroup]="filtersFormGroup"
            *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder"
        >
            <dtm-ui-input-field class="control-text">
                <input
                    matInput
                    type="text"
                    [formControl]="textControl"
                    [placeholder]="'uavIdClientLibMyReports.myReportsListFilters.textSearchLabel' | transloco"
                />
            </dtm-ui-input-field>

            <dtm-ui-select-field multiple class="control-status" [formControl]="statusesControl">
                <label>{{ "uavIdClientLibMyReports.myReportsListFilters.reportStatusLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let reportStatus of statuses$ | ngrxPush" [value]="reportStatus">{{
                    "uavIdClientLibMyReports.myReportsListFilters.reportStatusValueLabel" | transloco : { value: reportStatus }
                }}</dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-date-field class="control-date-from">
                <label>{{ "uavIdClientLibMyReports.myReportsListFilters.createdDateFromLabel" | transloco }}</label>
                <input
                    matInput
                    [formControl]="createdDateFromControl"
                    [matDatepicker]="fromPicker"
                    [placeholder]="datePickerPlaceholder"
                    [max]="createdDateToControl.value"
                />
                <mat-datepicker #fromPicker></mat-datepicker>
            </dtm-ui-date-field>

            <dtm-ui-date-field class="control-date-to">
                <label>{{ "uavIdClientLibMyReports.myReportsListFilters.createdDateToLabel" | transloco }}</label>
                <input
                    matInput
                    [formControl]="createdDateToControl"
                    [matDatepicker]="toPicker"
                    [placeholder]="datePickerPlaceholder"
                    [min]="createdDateFromControl.value"
                />
                <mat-datepicker #toPicker></mat-datepicker>
            </dtm-ui-date-field>

            <div class="control-emergency">
                <span>{{ "uavIdClientLibMyReports.myReportsListFilters.isEmergencyReportLabel" | transloco }}</span>
                <mat-slide-toggle [formControl]="isEmergencyReportControl"> </mat-slide-toggle>
            </div>

            <dtm-ui-select-field
                class="sort-select"
                [formControl]="sortControl"
                [compareWith]="compareSelectOptions"
                [isClearable]="false"
                (selectionChange)="sortChange.emit($event.value)"
            >
                <label>{{ "uavIdClientLibMyReports.myReportsListFilters.sortLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let sort of SORTING_OPTIONS" [value]="{ active: sort.active, direction: sort.direction }">{{
                    sort.translateKey | transloco : { value: sort.direction }
                }}</dtm-ui-select-option>
            </dtm-ui-select-field>
        </form>

        <dtm-ui-filter-chips-display
            selectedFiltersDisplaySlot
            [class.chips-margin]="!!appliedFiltersCount"
            [formGroup]="filtersFormGroup"
            [filtersValue]="filtersFormGroup.value"
            [filtersMap]="FILTERS_MAP"
            [filtersCount]="appliedFiltersCount"
            (allFiltersReset)="clearFilters()"
        >
        </dtm-ui-filter-chips-display>
    </dtm-ui-filters-container>
</ng-container>
