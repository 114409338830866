<div
    *ngrxLet="{
        isAuthorized: isAuthorized$,
        isIntervention: isIntervention$,
        isFlowSimplified: isFlowSimplified$,
        reportNumber: reportNumber$
    } as vm"
    class="container container-card"
>
    <div class="container-content scroll-shadows">
        <img ngSrc="assets/images/report-submit.svg" alt="" [width]="IMG_WIDTH" [height]="IMG_HEIGHT" />
        <div *ngIf="vm.isIntervention; else reportContentTemplate">
            <h2>{{ "uavIdClientLibReport.reportFormWizardThankYouPage.interventionHeader" | transloco }}</h2>

            <ng-container [ngTemplateOutlet]="reportNumberTemplate"></ng-container>

            <p>{{ "uavIdClientLibReport.reportFormWizardThankYouPage.interventionHintMessage" | transloco }}</p>
        </div>

        <ng-template #reportContentTemplate>
            <div *ngIf="vm.isFlowSimplified">
                <h2>{{ "uavIdClientLibReport.reportFormWizardThankYouPage.reportHeader" | transloco }}</h2>

                <ng-container [ngTemplateOutlet]="reportNumberTemplate"></ng-container>

                <p>{{ "uavIdClientLibReport.reportFormWizardThankYouPage.simplifiedFlowHintMessage" | transloco }}</p>
            </div>

            <div *ngIf="!vm.isFlowSimplified">
                <h2>{{ "uavIdClientLibReport.reportFormWizardThankYouPage.reportHeader" | transloco }}</h2>

                <ng-container [ngTemplateOutlet]="reportNumberTemplate"></ng-container>

                <h3>{{ "uavIdClientLibReport.reportFormWizardThankYouPage.whatIsNextLabel" | transloco }}</h3>
                <p *ngIf="vm.isAuthorized; else createAccountMessageTemplate">
                    {{ "uavIdClientLibReport.reportFormWizardThankYouPage.whereToCheckReportProgressLabel" | transloco }}
                </p>
                <ng-template #createAccountMessageTemplate>
                    <p>{{ "uavIdClientLibReport.reportFormWizardThankYouPage.createAccountLabel" | transloco }}</p>
                </ng-template>

                <p>
                    {{ "uavIdClientLibReport.reportFormWizardThankYouPage.informAboutReportProgressLabel" | transloco }}
                    {{ "uavIdClientLibReport.reportFormWizardThankYouPage.contactDescriptionLabel" | transloco }}
                </p>
            </div>
        </ng-template>

        <ng-template #reportNumberTemplate>
            <div class="report-number-container">
                <span>{{ "uavIdClientLibReport.reportFormWizardSummaryStep.reportNumberLabel" | transloco }}</span>
                <div>{{ vm.reportNumber }}</div>
            </div>
        </ng-template>
    </div>

    <div class="container-actions">
        <button class="button-secondary" type="button" routerLink="/report/user-geolocation">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
            {{ "uavIdClientLibReport.reportFormWizardThankYouPage.goBackToHomePageButtonLabel" | transloco }}
        </button>
        <ng-container *ngIf="vm.isAuthorized">
            <button
                *ngIf="vm.isIntervention; else goToMyReportsButtonTemplate"
                class="button-primary"
                type="button"
                [routerLink]="MY_INTERVENTIONS_URL"
            >
                {{ "uavIdClientLibReport.reportFormWizardThankYouPage.goToMyInterventionsPanelButtonLabel" | transloco }}
            </button>

            <ng-template #goToMyReportsButtonTemplate>
                <button class="button-primary" type="button" [routerLink]="MY_REPORTS_URL">
                    {{ "uavIdClientLibReport.reportFormWizardThankYouPage.goToMyReportsPanelButtonLabel" | transloco }}
                </button>
            </ng-template>
        </ng-container>
    </div>
</div>
